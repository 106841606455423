<template>
    <div class="my_box" v-bind:style="{ height: Height + 'px' }">
        <!-- 我的 -->
        <el-container v-bind:style="{ height: Height + 'px' }">
            <el-main>
                <Header class="headers"></Header>
                <Help v-if="keys == '1'" :name="tabName"></Help>
                <div v-if="keys == '2'">
                    <Question v-if="isRouterAlive"></Question>
                </div>

                <Information v-if="keys == '3'" @newTotal="unread"></Information>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import Help from "./my_component/help.vue";
import Header from "./my_component/header.vue";
import Question from "./my_component/question.vue";
// Information;
import Information from "./my_component/Information.vue";
export default {
    inject: ["reloads"],
    provide() {
        return {
            reload: this.reload,
            Height: 0,
        };
    },
    data() {
        return {
            keys: "1",
            tabName: "issuse",
            total: "",
            isRouterAlive: true,
        };
    },
    created() {
        if (this.$route.query.type) {
            this.tabName = this.$route.query.type
        }
        if (this.$route.query.webPage) {
            this.keys = this.$route.query.webPage.toString();
        }
        this.Height = document.documentElement.clientHeight - 80;
    },
    mounted() {
        //动态设置内容高度 让footer始终居底   header+footer的高度是190
        this.Height = document.documentElement.clientHeight - 80; //监听浏览器窗口变化
        window.onresize = () => {
            this.Height = document.documentElement.clientHeight - 80;
        };
        console.log(this.Height);
        // 监听当前页面 显示状态
        window.addEventListener("visibilitychange", this.hanldeVisiblityChange);

        // 当页面被销毁时 移除监听
        this.$on("hook:beforeDestroy", () => {
            // console.info("ws 我被销毁了, 移除监听>>>");
            window.removeEventListener(
                "visibilitychange",
                this.hanldeVisiblityChange
            );
        });
    },
    // 监听路由变化
    watch: {
        $route(to, from) {
            if (to.query.webPage) {
                this.keys = to.query.webPage.toString();
                console.log(this.keys);
            }
        },
    },
    methods: {
        handleSelect(key) {
            // console.log(key);
            this.keys = key;
            this.activeIndex = key;
            if (key !== "4") {
                this.$router.push({
                    name: "Personal",
                    query: { webPage: key },
                });
            }

            if (key == "4") {
                this.$confirm("确认退出账号？")
                    .then((_) => {
                        this.$router.push({
                            name: "Login",
                        });
                    })
                    .catch((_) => {
                        this.keys = "1";
                        // console.log('aa')
                        this.reloads();
                    });
            }
        },
        // testData(data) {
        //   // console.log("parent");
        //   // console.log(data)
        //   if (data > 0) {
        //     this.total = data;
        //   }
        // },
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
        unread(data) {
            console.log(data);
            if (data == 0) {
                this.total = "";
            } else {
                this.total = data;
            }
        },
    },
    components: {
        Help,
        Header,
        Question,
        Information,
    },
};
</script>

<style lang="less" scoped>
.my_box {
    background-color: #e7ebfa;
    .el-main {
        background-color: #e7ebfa;
        color: #333;
        min-width: 1021px;
        height: 100%;
    }

    body>.el-container {
        margin-bottom: 40px;
    }

    .el-container:nth-child(5) .el-aside,
    .el-container:nth-child(6) .el-aside {
        line-height: 260px;
    }

    .el-container:nth-child(7) .el-aside {
        line-height: 320px;
    }

    .el-menu {
        background-color: #fff;
    }
}

//选中
.is-active div {
    background-color: #B14A75;
    border-radius: 5px;

    span {
        color: #fff;
    }

    i {
        color: #fff;
    }
}

.el-menu-item {
    padding: 5px 50px !important;
    background-color: #fff;
}

.aside_item {
    width: 100%;
    height: 50px;
    line-height: 50px;

    span {
        color: #b1aec8;
        font-size: 15px;
    }
}

span {
    height: 30px;

    .item {
        height: 30px;
        line-height: 30px;

        /deep/ .el-badge__content.is-fixed {
            position: absolute;
            top: 0px;
            right: 13px;
            transform: translateY(-50%) translateX(100%);
        }
    }
}
</style>