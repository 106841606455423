<template>
  <div class="Information" v-bind:style="{ minHeight: Height + 'px' }">
    <!-- <el-upload
      class="avatar-uploader"
      action="string"
      :show-file-list="false"
      :http-request="uploadImage"
      :before-upload="beforeAvatarUpload"
    >
      <img v-if="form.picUrl" :src="form.picUrl" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload> -->
    <div>
      <h2 style="display: inline-block; height: 50px; line-height: 50px">
        消息中心
      </h2>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="系统消息" name="unread">
          <div v-if="messageList.length == 0" class="without">
            <img
              src="../../../assets/imgs/no_msg.png"
              alt=""
              style="height: 500px"
            />
          </div>
          <ul v-if="messageList.length > 0">
            <li v-for="(item, index) in messageList" :key="index">
              <h3 class="title">
                <span class="unread" v-if="item.record_id == null"></span
                >{{ item.title }}
              </h3>
              <div class="parts">
                <p class="time">
                  {{ item.timestramp | parseTime("{y}-{m}-{d} {h}:{i}") }}
                </p>
                <p class="part" @click="open(item)">
                  <span class="content">{{ item.desc }}</span
                  ><i class="el-icon-arrow-right"></i
                  ><i class="el-icon-arrow-right"></i>
                </p>
              </div>
            </li>
          </ul>
        </el-tab-pane>
        <el-tab-pane label="个人消息" name="read">
          <div v-if="messageList01.length == 0" class="without">
            <img
              src="../../../assets/imgs/no_msg.png"
              alt=""
              style="height: 500px"
            />
          </div>
          <ul v-if="messageList01.length > 0">
            <li v-for="(item, index) in messageList01" :key="index">
              <h3 class="title">{{ item.title }}</h3>
              <div class="parts">
                <p class="time">
                  {{ item.timestramp | parseTime("{y}-{m}-{d} {h}:{i}") }}
                </p>
                <p class="part" @click="open(item)">
                  <span class="content">{{ item.desc }}</span
                  ><i class="el-icon-arrow-right"></i
                  ><i class="el-icon-arrow-right"></i>
                </p>
              </div>
            </li>
          </ul>
        </el-tab-pane>
        <!-- <el-tab-pane label="近三天" name="three"
          ><div v-if="days.length == 0" class="without">
            <img
              src="../../../assets/imgs/no_msg.png"
              alt=""
              style="height: 500px"
            />
          </div>
          <ul v-if="days.length > 0">
            <li v-for="(item, index) in days" :key="index">
              <h3 class="title">{{ item.title }}</h3>
              <div class="parts">
                <p class="time">
                  {{ item.timestramp | parseTime("{y}-{m}-{d} {h}:{i}") }}
                </p>
                <p class="part" @click="three_open(item)">
                  <span class="content">{{ item.desc }}</span
                  ><i class="el-icon-arrow-right"></i
                  ><i class="el-icon-arrow-right"></i>
                </p>
              </div>
            </li></ul
        ></el-tab-pane> -->
      </el-tabs>
      <!-- <div class="folds">
      <el-collapse v-for="(item, index) in messageList" :key="index" v-model="activeNames" @change="handleChange(item)" accordion>
      <el-collapse-item>
        <template slot="title">
          {{ item.title }}<i class="header-icon el-icon-info"></i>
        </template>
        <div>
          {{ item.timestramp | parseTime("{y}-{m}-{d} {h}:{i}") }}
        </div>
        <div>
          {{ elements }}
        </div>
        <p>{{ con }}</p>
      </el-collapse-item>
    </el-collapse>
    </div> -->
      <el-dialog :title="newTitle" :visible.sync="dialogVisible" width="30%">
        <p style="font-size: 16px">{{ elements }}</p>
        <p style="white-space: pre-wrap; line-height: 20px; margin-top: 20px">
          {{ con }}
        </p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  information,
  threeDay,
  fetchMessage,
  readMessage,
} from "@/Api/userMessage";
// import { parseTime } from "@/utils/date";
export default {
  data() {
    return {
      activeName: "unread",
      message: {},
      messageList: [],
      messageList01: [],
      dialogVisible: false,
      newTitle: "",
      elements: "",
      days: [],
      con: "",
      activeNames: ["1"],
      Height: 0,
    };
  },
  created() {
    this.handleClick();
  },
  mounted() {
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    this.Height = document.documentElement.clientHeight - 400; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 400;
    };
  },
  methods: {
    handleClick() {
      // console.log(tab.label);
      // console.log(this.activeName);
      this.messageList = [];
      this.messageList01 = [];
      fetchMessage()
        .then((res) => {
          console.log(res);
          let record = 0;
          res.data.data.forEach((item) => {
            if(item.record_id == null){
              ++record
            }
            if (item.msg_type == 3) {
              this.messageList01.push(item);
            } else {
              this.messageList.push(item);
            }
          });
          // console.log(record)
          this.$emit("newTotal", record);
        })
        .catch((err) => {
          console.log(err);
        });
      // if (this.activeName == "unread" || this.activeName == "read") {
      //   if (this.activeName == "unread") {
      //     this.message = {
      //       page: 1,
      //       type: 1,
      //     };
      //   }
      //   if (this.activeName == "read") {
      //     this.message = {
      //       page: 1,
      //       type: 0,
      //     };
      //   }
      //   information(this.message)
      //     .then((res) => {
      //       // console.log(res);
      //       this.messageList = res.data.data.data;
      //       console.log();
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });

      // }

      // if (this.activeName == "three") {
      //   let ids = {
      //     id: "",
      //   };
      //   threeDay(ids)
      //     .then((res) => {
      //       console.log(res);
      //       this.days = res.data.data;
      //       // console.log();
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
      // }
    },
    open(it) {
      // console.log(it);
      // this.dialogVisible = true;
      // this.newTitle = it.title;
      // this.elements = it.desc;
      
      if (it.record_id == null) {
        let read_id = {
          msg_id: it.id,
        };
        readMessage(read_id)
          .then((res) => {
            console.log(res);
            this.handleClick();
            // res.data.data.forEach((item) => {
            //   if (item.msg_type == 3) {
            //     this.messageList01.push(item);
            //   } else {
            //     this.messageList.push(item);
            //   }
            // });
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.newTitle = it.title;
      this.elements = it.desc;
      this.con = it.message;
      this.dialogVisible = true;
      // let ids = {
      //   id: it.id,
      // };
      // threeDay(ids)
      //   .then((res) => {
      //     // console.log(res);
      //     this.newTitle = res.data.data.title;
      //     this.elements = res.data.data.desc;
      //     this.con = res.data.data.con;
      //     // this.days = res.data.data;
      //     // console.log();
      //     let mes = {
      //       page: 1,
      //       type: 1,
      //     };
      //     if (this.activeName == "unread") {
      //       information(mes)
      //         .then((res) => {
      //           // console.log(res);
      //           this.$emit("newTotal", res.data.data.total);
      //           // console.log();
      //         })
      //         .catch((err) => {
      //           console.log(err);
      //         });
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    three_open(it) {
      // console.log(it);

      this.dialogVisible = true;
      let ids = {
        id: it.id,
      };
      threeDay(ids)
        .then((res) => {
          // console.log(res);
          this.newTitle = res.data.data.title;
          this.elements = res.data.data.desc;
          this.con = res.data.data.con;
          // this.days = res.data.data;
          // console.log();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 打开折叠面板
    handleChange(i, val) {
      // console.log(i, val);
      // this.dialogVisible = true;
      let ids = {
        id: i.id,
      };
      threeDay(ids)
        .then((res) => {
          // console.log(res);
          this.newTitle = res.data.data.title;
          this.elements = res.data.data.desc;
          this.con = res.data.data.con;
          // this.days = res.data.data;
          // console.log();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.Information {
  // margin: 20px;
  // min-width: 986px;
  background-color: #fff;
  display: flex;
  justify-content: center;

  // padding: 0 10%;
  .folds {
    // padding: 0 10%;
    background: rgb(255, 255, 255);
    // padding: 0 20%;
  }
  /deep/ .el-tabs--border-card {
    box-shadow: none;
    border: none;
  }
  /deep/ .el-tabs__header {
    // margin-left: 20px;
  }
  .el-tabs {
    // padding: 0 20%;
    min-width: 880px;
    /deep/ .el-tabs__content {
      min-height: 500px;
      .without {
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      ul {
        // margin: 0 20%;
        li {
          // display: inline-block;
          // width: 100%;
          height: 100px;
          margin: 20px 0;
          border-radius: 50px;
          // border: 1px solid #ca0000;
          h3 {
            padding: 10px;
            background: rgb(230, 230, 230);
            // border-radius: 10px;
            border-start-start-radius: 10px;
            border-start-end-radius: 10px;
          }
          .parts {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            background: rgb(240, 239, 239);
            border-end-start-radius: 10px;
            border-end-end-radius: 10px;
            p {
              padding: 5px 0;
              display: inline-block;
            }
            .part {
              .content {
                display: inline-block;
                // width: 55px;
                // white-space: nowrap;
                // text-overflow: ellipsis;
                // overflow: hidden;
              }
            }
            .part:hover {
              color: rgb(0, 153, 255);
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
.unread {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: rgb(255, 0, 0);
  border-radius: 5px;
  position: relative;
  top: -15px;
  left: -10px;
}
.addRemark:hover{
  color: #6861c9;
}
</style>