<template>
  <div class="headers">
    <div class="my_header">
      <div class="message">
        <h2>账户信息</h2>
        <div style="display: flex">
          <img v-if="headshot" :src="headshot" alt="" />
          <img v-else src="../../../assets/imgs/user/header_user.png" alt="" />
          <div class="meaasge_box">
            <div class="tag">
              <span class="name">{{ name }}</span>
              <el-tag effect="dark" class="accountType" v-if="id_photo">正式账号</el-tag>
              <!-- <el-tag effect="dark" type="warning" class="accountType" v-else-if="status == 2">试用用户</el-tag>
              <el-tag effect="dark" type="danger" class="accountType" v-else>非正式用户</el-tag> -->
              <el-tag effect="dark" type="warning" class="accountType" v-else>临时用户</el-tag>
              <span v-if="status == 2 || status == 4" @click="textmsg">
                <i class="el-icon-question" style="font-size:20px;cursor: pointer;"></i>
              </span>
                <el-tag class="groupName" v-if="source_name !== ''"
                  ><el-tooltip
                class="item"
                effect="dark"
                :content="source_name"
                placement="bottom"
              ><span>{{ source_name }}</span></el-tooltip></el-tag
                >
              
            </div>
            <div class="email">邮箱：{{ email }}</div>
            <div class="phone">电话：{{ phone }}</div>
          </div>
        </div>
      </div>
      <div class="message">
        <h2>文献信息</h2>
        <el-table :data="tableData" stripe class="literatureInfo">
          <el-table-column prop="type" label="类型"> </el-table-column>
          <el-table-column prop="中文书" label="中文书"> </el-table-column>
          <el-table-column prop="英文书" label="英文书"> </el-table-column>
          <el-table-column prop="中文文献" label="中文文献"> </el-table-column>
          <el-table-column prop="英文文献" label="英文文献"> </el-table-column>
          <el-table-column prop="其他" label="其他"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { personalMessage, wenNumber } from "@/Api/userMessage";
export default {
  data() {
    return {
      name: "",
      id_photo:false,
      source_name: "",
      email: "",
      phone: "",
      tableData: [],
      headshot: "",
      status: localStorage.getItem('status')
    };
  },
  created() {
    if(sessionStorage.getItem('headshot')){
      this.headshot = sessionStorage.getItem('headshot')
    }
    this.message();
  },
  methods: {
    textmsg(){
      // 判断status
      if(this.status == 2){
        this.$message({
          message: '您当前的账号状态，为了更精确的确认您的身份信息，请您完善您的个人信息与证件',
          type: 'warning'
        });
      }else if(this.status == 4){
        this.$message({
          message: '账号审核未通过，账号信息不符合规则，您可以修改账号信息',
          type: 'warning'
        });
      }
    },
    message() {
      personalMessage()
        .then((res) => {
          // console.log(res);
          this.name = res.data.data.name;
          this.id_photo = res.data.data.id_photo;
          this.source_name = res.data.data.belong_name;
          this.email = res.data.data.email;
          this.phone = res.data.data.phone;
          this.headshot = res.data.data.avatarUrl;
          sessionStorage.setItem('headshot',res.data.data.avatarUrl)
          sessionStorage.setItem("unionid", res.data.data.unionid);
        })
        .catch((err) => {
          console.log(err);
        });
      wenNumber()
        .then((res) => {
          // console.log(res);
          let settingObj = res.data.data;
          let settingArr = Object.keys(settingObj).map((key) => {
            // console.log(key); //为每个键名
            if (key == "year") {
              settingObj["year"].type = "本年";
            }
            if (key == "day") {
              settingObj["day"].type = "本日";
            }
            return settingObj[key]; //把每个对象返回出去生成一个新的数组中相当于0:{id:1}
          });
          // console.log(settingArr);
          this.tableData = settingArr;
          // for (var item of type) {
          //   console.log(item)
          // }
          // this.name = res.data.data.name;
          // this.source_name = res.data.data.source_name;
          // this.email = res.data.data.email;
          // this.phone = res.data.data.phone;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang='less' scoped>
.headers {
  // margin: 20px;
  
  background-color: #ffffff;
  // display: flex;
  // justify-content: center;
  padding: 0 20px;
  // min-width: 986px;
  .my_header {
    display: flex;
    justify-content: center;
    // min-width: 880px;
    .message {
      // min-width: 450px;
      // width: 45%;
      // margin-right: 20px;
      background: white;
      padding: 20px 0px 20px 20px;
      border-radius: 5px;
      .literatureInfo {
        width: 100%;
        :nth-child(1) {
          font-weight: 600;
        }
      }
      img {
        margin: 20px;
        width: 120px;
        // width: 40%;
        // max-width: 120px;
        height: 120px;
        border-radius: 60px;
      }

      .meaasge_box {
        line-height: 20px;
        margin: 25px 0;
        min-width: 120px;
        .tag {
          display: flex;
          align-items: center;
          span {
            // display: inline-block;
          }
          .name {
            font-size: 16px;
            font-weight: 600;
            min-width: 48px;
          }
          .accountType {
            // background-color: #74d8ca;
            // color: #fff;
            // border-radius: 0;
          }
          .groupName {
            background-color: #72b3d9;
            color: #fff;
            margin: 0 10px;
            // border-radius: 0;
            span {
              display: inline-block;
              width: 60px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
        div {
          margin: 10px 0;
        }
      }
    }
  }
}
</style>