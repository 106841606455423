<template>
  <div class="my_help" v-bind:style="{ minHeight: Height + 'px' }">
    <div class="assist">
      <h2>帮助中心</h2>
      <el-tabs v-model="activeName" @tab-click="handleClick" class="help_item">
        <!-- <el-tab-pane label="入门指南" name="entry">入门指南</el-tab-pane> -->
        <el-tab-pane label="使用指南" name="use">
          <el-collapse v-model="activeGuild" @change="handleGuild" accordion>
            <el-collapse-item title="智能咨询" name="1">
              <div>1.一框式检索，智能识别咨询问题</div>
              <div>
                通过一框式检索，系统可智能识别用户输入的文献需求或参考咨询问题，以下是文献咨询和参考咨询的正确提问示例：
                <br />
                文献咨询示例：
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image1.png"
                alt=""
              />
              <div>参考咨询示例：</div>
              <img
                src="../../../assets/imgs/user/new_personal/image2.png"
                alt=""
              />
              <div>2.问题类型切换</div>
              <div>
                如果AI对问题类型判断有误，可手动对问题类型进行修改，修改后将重新回答该问
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image3.png"
                alt=""
              />
              <div>
                如只需进行文献查找，可在左侧菜单点击“文献查找”，所输入问题将统一识别为文献需求；详细操作见“文献查找”部分；
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image4.png"
                alt=""
              />
            </el-collapse-item>
            <el-collapse-item title="资源发现" name="2">
              <div>平台集成了已购资源数据库，机构已开通的数据库会展示在页面顶部标签，通过青梨证件审核的正式用户可以访问使用；</div>
              <img
                src="../../../assets/imgs/user/new_personal/image6.png"
                alt=""
              />
            </el-collapse-item>
            <el-collapse-item title="文献查找" name="3">
              <div>文献检索</div>

              <div>用户在检索时需提供相应的关键词，方便用户更好的检索。</div>
              <div>1.检索入口</div>
              <div>
                将需要查找的需求粘贴到需求框中，一行一个需求，同一需求的多个条件可用空格或分号分开
              </div>
              <div>正确示例：</div>
              <img
                src="../../../assets/imgs/user/new_personal/image7.png"
                alt=""
              />
              <div>正确示例：</div>
              <img
                src="../../../assets/imgs/user/new_personal/image8.png"
                alt=""
              />
              <div>2.对参考文献的识别</div>
              <div>
                ●
                系统会对参考文献格式中的篇名进行识别和检索，但篇名可能会出现重复的情况；<br />
                ● 添加需求后点击识别，可点击编辑按钮对识别后的文献进行修改；<br />
                ● 需求类型如果识别有误，可自行修改；<br />
                ● 如对需求有特殊需求，可在备注中说明；<br />
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image11.png"
                alt=""
              />
              <img
                src="../../../assets/imgs/user/new_personal/image13.png"
                alt=""
              />
              <div>资源管理</div>
              <div>资源管理主要整合用户检索过的文献</div>
              <div>1.处理状态</div>
              <div>
                处理成功状态会变为
                资源详情，点击资源详情复制链接到浏览器下载更稳定，部分文献支持在线浏览；
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image15.png"
                alt=""
              />
              <div>处理失败会转二次处理，需要的时间会久一点~请耐心等待哦；</div>
              <img
                src="../../../assets/imgs/user/new_personal/image17.png"
                alt=""
              />
              <div>点击复选框可以批量导出处理成功的查询结果，方便记录；</div>
              <img
                src="../../../assets/imgs/user/new_personal/image19.png"
                alt=""
              />
              <div>点击文献可查看详情；</div>
              <img
                src="../../../assets/imgs/user/new_personal/image22.png"
                alt=""
              />
              <div>提供链接地址的文献需求可通过操作对文献进行删除或反馈；</div>
              <img
                src="../../../assets/imgs/user/new_personal/image24.png"
                alt=""
              />
              <div>2.筛选功能</div>
              <div>
                支持对文献类型、需求状态、时间/来源等维度进行筛序；<br />
                支持对历史文献进行检索；
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image26.png"
                alt=""
              />
            </el-collapse-item>
          </el-collapse>
        </el-tab-pane>
        <el-tab-pane label="常见问题" name="issuse">
          <el-collapse v-model="activeNames" @change="handleChange" accordion>
            <el-collapse-item
              title="1、青梨支持的文献传递资源包括哪些？"
              name="1"
            >
              <div>
                青梨支持提供包括中外文期刊、图书、学位论文、标准、工具书、会议、技术报告等多种类型资源。
              </div>
            </el-collapse-item>
            <el-collapse-item title="2、如何在青梨PC端提出需求？" name="2">
              <div>
                <span style="background: rgb(255, 230, 0)">强烈建议</span
                >您通过提交文献的DOI号、ISBN号、PMID号、URL地址等提交文献查找需求
                如果无法获取文献的以上信息，建议以<span
                  style="background: rgb(255, 230, 0)"
                  >一行一个需求</span
                >的方式提交，<br />样例如下：
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image7.png"
                alt=""
              />
              <div>不要采用以下方式提交</div>
              <img
                src="../../../assets/imgs/user/new_personal/image8.png"
                alt=""
              />
            </el-collapse-item>
            <el-collapse-item title="3、文献识别不准怎么办" name="3">
              <div>
                如果同一条需求被识别为多条需求，需要您确认下是否存在多余的换行。<br />
                合并需求，勾选需要合并的需求，点击合并需求即可将所选需求合并为一条；<br />
                样例如下：
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image29.jpeg"
                alt=""
              />
            </el-collapse-item>
            <el-collapse-item title="4、需求内容如何编辑修改？" name="4">
              <div>
                需求内容修改，点击编辑按钮，即可修改需求内容。样例如下：
              </div>

              <div><span style="color: red">ISBN位数不对</span></div>
              <img
                src="../../../assets/imgs/user/new_personal/image34.png"
                alt=""
              />
              <img
                src="../../../assets/imgs/user/new_personal/image37.png"
                alt=""
              />
            </el-collapse-item>
            <el-collapse-item title="5、怎么查看历史提交的文献" name="5">
              <div>
                在资源界面，可查看文献需求列表，支持文献类型、需求状态、时间/来源等维度进行筛序。<br />
                查看样例
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image26.png"
                alt=""
              />
            </el-collapse-item>
            <el-collapse-item title="6、链接是否会过期？" name="6">
              <div> 中文图书链接有效期为72小时，其他类型需求链接有效期为24小时。</div>
            </el-collapse-item>

            <el-collapse-item title="7、是否可以看到提交文献的详情？" name="7">
              <div>
                鼠标悬浮在需求名称处，可以看到该文献的详细信息。<br />
                查看样例
              </div>
              <img
                src="../../../assets/imgs/user/new_personal/image22.png"
                alt=""
              />
            </el-collapse-item>
            <el-collapse-item title="8、每天提出的文献需求是否有上限？" name="8">
              <div>每天提交的文献需求存在上限，可在“我的”页面查看各类需求的上限数量。</div>
              <!-- <img src="../../../assets/imgs/图片12.png" alt="" /> -->
            </el-collapse-item>
           </el-collapse>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "issuse",
    },
  },
  data() {
    return {
      activeName: this.name,
      activeNames: [""],
      activeGuild: [""],
      Height: 0,
      // Guild
    };
  },
  mounted() {
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    this.Height = document.documentElement.clientHeight - 400; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 400;
    };
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    handleChange(val) {
      // console.log(val);
    },
    handleGuild(val) {
      // console.log(val);
    },
  },
};
</script>

<style lang="less" scoped>
.my_help {
  // margin: 20px;
  // min-width: 901px;

  background-color: #fff;
  display: flex;
  justify-content: center;
  // padding: 0 20%;
  .assist {
    // padding: 0 20px;
    // margin: 10px 0;
    width: 901px;
    h2 {
      height: 50px;
      line-height: 50px;
      // padding-left: 5px;
    }
    .question {
      li {
        height: 50px;
        line-height: 50px;
      }
    }
  }
}
.help_item {
  // padding: 0 20px;
  // width: 50%;
  .el-tab-pane {
    width: 100%;
    .el-collapse {
      width: 100%;
    }
  }
  div {
    // text-indent:2em;
  }
  img {
    width: 100%;
  }
  /deep/ .el-tabs__content {
    min-height: 300px;
  }
  .is-active {
    color: #b14a75;
  }
}
/deep/ .el-tabs__item.is-active {
  color: #b14a75;
}
/deep/ .el-tabs__active-bar {
  background-image: linear-gradient(to bottom, #b14a75, #b14a75);
}
.a {
  background: rgb(255, 208, 0);
}
</style>