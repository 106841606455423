<template>
  <div class="questions" v-bind:style="{ minHeight: Height + 'px' }">
    <div class="assist">
      <h2>反馈中心</h2>
      <div>
        <h5>
          感谢您对青梨小程序的支持，如有任何问题或建议可以在下方反馈给我们，工作人员会尽快给您回复。回复内容会通过邮件发送给您，请注意查收！
        </h5>
      </div>
      <h4>您的详细诉求和意见建议</h4>
      <el-input
        type="textarea"
        :rows="7"
        placeholder="请输入反馈内容"
        v-model="textarea"
        style="margin: 10px 0"
        @input="inputFunc"
      >
      </el-input>
      <h4>相关佐证图片资料(最多可以上传3张图片)</h4>
      <el-upload
        action=""
        multiple
        list-type="picture-card"
        :auto-upload="false"
        :limit="3"
        :on-exceed="exceed"
        :on-change="changeFileList"
        :on-remove="changeFileList"
      >
        <i class="el-icon-plus"></i>
      </el-upload>

      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
      <el-button
        style="
          width: 150px;
          margin: 10px 0;
          background-image: linear-gradient(to bottom, #B14A75, #B14A75);
          color: #fff;
        "
        size="big"
        @click="submitUpload"
        >提交</el-button
      >
    </div>
  </div>
</template>

<script>
import { getuploadtoken } from "@/Api/upload";
import { submit } from "@/Api/question";
import Client from "@/utils/client";
import store from "@/store/index";
export default {
  inject: ["reload"],
  data() {
    return {
      // 反馈相关参数
      dataObj: {},
      imgList: [],
      textarea: "",
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      Height: 0,
    };
  },
  created() {
    //请求后台接口返回授权数据
    this.getAliToken();
  },
  mounted() {
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    this.Height = document.documentElement.clientHeight - 400; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 400;
    };
  },
  methods: {
    // 反馈内容input输入监听事件
    inputFunc(e) {
      this.textarea = e;
      // console.log(this.textarea);
    },
    changeFileList(file, fileList) {
      this.imgList = fileList;
    },
    exceed() {
      this.$message({
        message: "最多可以上传3张图片",
        type: "error",
        duration: 3000,
      });
    },
    getAliToken() {
      return new Promise((resolve, reject) => {
        //请求后台接口返回授权数据
        getuploadtoken()
          .then((response) => {
            this.dataObj = {
              region: "oss-cn-beijing",
              bucket: "ql-applet",
              accessKeyId: response.data.data.AccessKeyId,
              accessKeySecret: response.data.data.AccessKeySecret,
              security: response.data.data.SecurityToken,
            };
            resolve(true);
          })
          .catch((err) => {
            reject(false);
          });
      });
    },
    // 提交反馈
    submitUpload() {
      // console.log("this.imgList", this.imgList);
      // return;
      // 上传文件
      let fileList = this.imgList;
      var img = "";

      // 拼接文件路径
      var AData = new Date();
      var fileName =
        "feedback_img/" +
        AData.getFullYear() +
        "-" +
        (AData.getMonth() + 1) +
        "-" +
        AData.getDate() +
        "/" +
        AData.getTime();
      for (let i = 0; i < fileList.length; i++) {
        // 截取文件后缀名
        let fileTypeIndex = fileList[i].name.lastIndexOf(".");
        let fileType = fileList[i].name.substring(fileTypeIndex);

        // 拼接完整文件路径
        let path =
          fileName +
          Math.round(Math.random() * 6) +
          Math.round(Math.random() * 6) +
          fileType;

        // 上传图片至阿里云OSS
        this.handleHttpRequest(fileList[i].raw, path);
        console.log("path", path);
        img += path + ";";
      }
      var useridea = {
        con: this.textarea, // 反馈内容
        email: localStorage.getItem("email"), // 邮箱
        phone: localStorage.getItem("phone"), // 手机号
        // email: '30565257@qq.com', // 邮箱
        // phone: '12345678', // 手机号
        img: img, // 反馈图片
        source: "qingli",
      };
      if(!this.textarea){
        this.$message({
          message: "请输入反馈内容",
          type: "warning",
        });
        return
      }
      submit(useridea).then((res) => {
        // 提交成功 弹出提示框并刷新页面
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        // location. reload()
        this.reload();
        // this.textarea = '',

        // console.log(res);
      });
    },
    async handleHttpRequest(option, path) {
      console.log("path");
      //上传OSS
      try {
        const client = Client(this.dataObj),
          file = option;
        await client
          .multipartUpload(path, file, {})
          .then(({ res }) => {
            console.log("res", res);
            return "上传成功";
          })
          .catch((error) => {
            console.log("error", error);
            return "上传失败";
          });
      } catch (error) {
        console.log("error", error);
        return "上传失败";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.questions {
  // margin: 15px;
  // min-width: 986px;
  background-color: #fff;

  // padding: 20px 20%;
  display: flex;
  justify-content: center;
  .assist {
    // padding: 20px;
    min-width: 901px;
    h2 {
      height: 50px;
      line-height: 50px;
      // padding-left: 5px;
    }
    h5 {
      height: 50px;
      line-height: 20px;
      font-weight: normal;
    }
    h4 {
      margin: 20px 0;
    }
  }
}
/deep/ .el-textarea__inner {
    resize: none;
  }
</style>